import {
  MEDIA_TYPES,
  toSrc,
  type MediaIds,
} from "@components/solid/Player/lib/toSrc";
import type { QueueMedia } from "@core/types";
import { type Plug, type PlugMap } from "src/types/plug.ts";

function throwExpression(message: string): never {
  throw new Error(message);
}

const mediaIdMap: PlugMap<MediaIds> = {
  episode: (plug) => ({
    mediaType: MEDIA_TYPES.PROGRAM,
    seriesId: plug.episode.seriesId,
    episodeId: plug.episode.programId,
    description: plug.episode.episodeTitle,
  }),
  standaloneProgram: (plug) => ({
    mediaType: MEDIA_TYPES.PROGRAM,
    episodeId: plug.standaloneProgram.programId,
    description: plug.standaloneProgram.programTitle,
  }),
  channel: (plug) => ({
    mediaType: MEDIA_TYPES.CHANNEL,
    channelId: plug.channel.channelId,
    description: plug.channel.channelTitle,
  }),
  podcastEpisode: (plug) => ({
    mediaType: MEDIA_TYPES.PODCAST,
    episodeId: plug.podcastEpisode.episodeId,
    seriesId: plug.podcastEpisode.podcastId,
    description: plug.podcastEpisode.podcastEpisodeTitle,
  }),
};

export const toMediaId = (plug: Plug): MediaIds => {
  switch (plug.type) {
    case "episode":
      return mediaIdMap.episode(plug);
    case "standaloneProgram":
      return mediaIdMap.standaloneProgram(plug);
    case "channel":
      return mediaIdMap.channel(plug);
    case "podcastEpisode":
      return mediaIdMap.podcastEpisode(plug);
    default:
      throwExpression(`Unsupported plug type ${plug.type}`);
  }
};

const plugToQueueMediaMap: PlugMap<QueueMedia> = {
  episode: (plug) => ({
    id: toSrc(toMediaId(plug)),
    duration: plug.episode.duration,
    episodeTitle: plug.episode.episodeTitle,
    title: plug.episode.seriesTitle,
    mediaId: toMediaId(plug),
  }),
  standaloneProgram: (plug) => ({
    id: toSrc(toMediaId(plug)),
    duration: plug.standaloneProgram.duration,
    episodeTitle: plug.standaloneProgram.programTitle,
    title: plug.title,
    mediaId: toMediaId(plug),
  }),
  channel: (plug) => ({
    id: toSrc(toMediaId(plug)),
    episodeTitle: plug.channel.channelTitle,
    title: plug.title,
    mediaId: toMediaId(plug),
  }),
  podcastEpisode: (plug) => ({
    id: toSrc(toMediaId(plug)),
    duration: plug.podcastEpisode.duration,
    episodeTitle: plug.podcastEpisode.podcastEpisodeTitle,
    title: plug.podcastEpisode.podcastTitle,
    mediaId: toMediaId(plug),
  }),
};

export const toQueueMedia = (plug: Plug): QueueMedia => {
  switch (plug.type) {
    case "episode":
      return plugToQueueMediaMap.episode(plug);
    case "standaloneProgram":
      return plugToQueueMediaMap.standaloneProgram(plug);
    case "channel":
      return plugToQueueMediaMap.channel(plug);
    case "podcastEpisode":
      return plugToQueueMediaMap.podcastEpisode(plug);
    default:
      throwExpression(`Unsupported plug type ${plug.type}`);
  }
};
