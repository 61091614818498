import { button, typography } from "@components/solid/classLists";
import {
  getQueue,
  isQueueInitialized,
  pushFirst,
  pushLast,
} from "@core/userMediaQueue";
import {
  nrkCheckActive,
  nrkMediaPlaylist,
  nrkMediaPlaylistAddLater,
  nrkMediaPlaylistAddNext,
  nrkSomeShare,
} from "@nrk/core-icons";
import type { Plug } from "@src-types/plug";
import { toQueueMedia } from "@utils/plug";
import { Show, type Component, type ParentComponent } from "solid-js";
import { Dynamic } from "solid-js/web";
import styles from "./MoreDialogResolver.module.css";

type Props = {
  plug: Plug;
  onClose?: () => void;
};

/* TODO: Load dialog only when user interacts with context button */

const MoreDialogResolver: ParentComponent<Props> = (props) => (
  <div data-plug-type={props.plug.type} class={styles["layout"]}>
    <Dynamic component={headers[props.plug.type]} {...props.plug} />
    <div class={styles["actions"]}>
      <Dynamic component={actions[props.plug.type]} {...props.plug} />
    </div>
    <Show when={props.onClose}>
      <button
        onClick={props.onClose}
        classList={button({ type: "secondary", variant: "text-icon" })}
      >
        Avbryt
      </button>
    </Show>
  </div>
);

type MoreDialogVariants = {
  [K in Plug["type"]]: Component<Extract<Plug, { type: K }>>;
};

const headers: MoreDialogVariants = {
  series: (props) => (
    <>
      <h1 classList={typography({ name: "Title3Bold" })}>
        {props.series.seriesTitle}
      </h1>
    </>
  ),
  episode: (props) => (
    <>
      <h1 classList={typography({ name: "Title3Bold" })}>
        {props.episode.episodeTitle}
      </h1>
      <span classList={typography({ name: "Footnote" })}>
        {props.episode.seriesTitle}
      </span>
    </>
  ),
  podcast: (props) => (
    <>
      <h1 classList={typography({ name: "Title3Bold" })}>
        {props.podcast.podcastTitle}
      </h1>
    </>
  ),
  podcastSeason: (props) => (
    <>
      <h1 classList={typography({ name: "Title3Bold" })}>
        {props.podcastSeason.podcastSeasonTitle}
      </h1>
      <span classList={typography({ name: "Footnote" })}>
        {props.podcastSeason.podcastTitle}
      </span>
    </>
  ),
  podcastEpisode: (props) => (
    <>
      <h1 classList={typography({ name: "Title3Bold" })}>
        {props.podcastEpisode.podcastEpisodeTitle}
      </h1>
      <span classList={typography({ name: "Footnote" })}>
        {props.podcastEpisode.podcastTitle}
      </span>
    </>
  ),
  standaloneProgram: (props) => (
    <>
      <h1 classList={typography({ name: "Title3Bold" })}>
        {props.standaloneProgram.programTitle}
      </h1>
    </>
  ),
  channel: (props) => (
    <>
      <h1 classList={typography({ name: "Title3Bold" })}>
        {props.channel.channelTitle}
      </h1>
    </>
  ),
  page: () => (
    <>
      <h1 classList={typography({ name: "Title3Bold" })}>Page</h1>
    </>
  ),
  link: () => (
    <>
      <h1 classList={typography({ name: "Title3Bold" })}>Link</h1>
    </>
  ),
};

const actions: MoreDialogVariants = {
  series: (props) => (
    <>
      <ShareButton link={props.series._links.share.href} />
      <Show when={isQueueInitialized()}>
        <div class={styles["queue-actions"]}>
          <PlayNextButton {...props} />
          <PlayLaterButton {...props} />
          <OpenQueueButton />
        </div>
      </Show>
      <MarkAsListenedButton />
    </>
  ),
  episode: (props) => (
    <>
      <ShareButton link={props.episode._links.share.href} />
      <Show when={isQueueInitialized()}>
        <div class={styles["queue-actions"]}>
          <PlayNextButton {...props} />
          <PlayLaterButton {...props} />
          <OpenQueueButton />
        </div>
      </Show>
      <MarkAsListenedButton />
    </>
  ),
  podcast: (props) => (
    <>
      <h1 classList={typography({ name: "Title3Bold" })}>
        {props.podcast.podcastTitle}
      </h1>
      <ShareButton link={props.podcast._links.share.href} />
      <MarkAsListenedButton />
    </>
  ),
  podcastSeason: (props) => (
    <>
      <ShareButton link={props.podcastSeason._links.share.href} />
      <div class={styles["queue-actions"]}>
        <OpenQueueButton />
      </div>
      <MarkAsListenedButton />
    </>
  ),
  podcastEpisode: (props) => (
    <>
      <ShareButton link={props.podcastEpisode._links.share.href} />
      <Show when={isQueueInitialized()}>
        <div class={styles["queue-actions"]}>
          <PlayNextButton {...props} />
          <PlayLaterButton {...props} />
          <OpenQueueButton />
        </div>
      </Show>
      <MarkAsListenedButton />
    </>
  ),
  standaloneProgram: (props) => (
    <>
      <ShareButton link={props.standaloneProgram._links.share.href} />
      <Show when={isQueueInitialized()}>
        <div class={styles["queue-actions"]}>
          <PlayNextButton {...props} />
          <PlayLaterButton {...props} />
          <OpenQueueButton />
        </div>
      </Show>
      <MarkAsListenedButton />
    </>
  ),
  channel: (props) => (
    <>
      <ShareButton link={props.channel._links.share.href} />
    </>
  ),
  page: (props) => (
    <>
      <ShareButton link={props.page._links.pageUrl.href} />
    </>
  ),
  link: (props) => (
    <>
      <ShareButton link={props.link._links.externalUrl.href} />
    </>
  ),
};

const ShareButton: Component<{ link: string }> = () => (
  <button
    autofocus
    classList={button({ type: "primary", variant: "text-icon" })}
  >
    <span>Del</span>
    <span innerHTML={nrkSomeShare} />
  </button>
);

const PlayNextButton: Component<Plug> = (props) => {
  const onClick = () => pushFirst(toQueueMedia(props));
  return (
    <button
      onClick={onClick}
      classList={button({ type: "primary", variant: "text-icon" })}
    >
      <span>Spill neste</span>
      <span innerHTML={nrkMediaPlaylistAddNext} />
    </button>
  );
};

const PlayLaterButton: Component<Plug> = (props) => {
  const onClick = () => pushLast(toQueueMedia(props));
  return (
    <button
      onClick={onClick}
      classList={button({ type: "primary", variant: "text-icon" })}
    >
      <span>Spill senere</span>
      <span innerHTML={nrkMediaPlaylistAddLater} />
    </button>
  );
};

const OpenQueueButton: Component = () => (
  <a
    href="/mittinnhold/ko/"
    classList={button({ type: "primary", variant: "text-icon" })}
  >
    <span>Se min kø ({isQueueInitialized() && getQueue().length})</span>
    <span innerHTML={nrkMediaPlaylist} />
  </a>
);

const MarkAsListenedButton: Component = () => {
  const onClick = () => {
    /* TODO: Add mark as listened logic */
  };

  return (
    <button
      onClick={onClick}
      classList={button({ type: "primary", variant: "text-icon" })}
    >
      <span>Merk som hørt</span>
      <span innerHTML={nrkCheckActive} />
    </button>
  );
};

export default MoreDialogResolver;
